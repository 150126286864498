<template>
  <v-container fluid>
    <Report />
  </v-container>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import Report from '@/components/Report.vue'

export default defineComponent({
  components: { Report },

  name: 'ReportUpdate',
})
</script>
