<template>
  <div>
    <v-card-title>
      레포트 {{ $route.params.uid ? '수정' : '등록' }}
      <v-btn v-if="$route.params.uid" class="ml-5" color="#ffd600" depressed rounded light @click="notification = true">
        발송하기
      </v-btn>
    </v-card-title>
    <v-row no-gutters class="mb-3">
      <v-card outlined width="320" class="mr-6">
        <v-card-actions>
          <v-btn text color="grey">발행 여부</v-btn>
          <v-spacer />
          <v-btn small depressed :text="status !== 'show'" :color="status !== 'show' ? null : '#ffd600'" :light="status === 'show'" @click="status = 'show'">
            바로 발행
          </v-btn>
          <v-btn
            small
            depressed
            :text="status !== 'pending'"
            :color="status !== 'pending' ? null : '#ffd600'"
            :light="status === 'pending'"
            @click="status = 'pending'"
          >
            발행 예약
          </v-btn>
          <v-btn small depressed :text="status !== 'keep'" :color="status !== 'keep' ? null : '#ffd600'" :light="status === 'keep'" @click="status = 'keep'">
            일단 보관
          </v-btn>
        </v-card-actions>
      </v-card>
      <v-card outlined width="400">
        <v-card-actions>
          <v-btn text color="grey">예약 시간</v-btn>
          <v-menu v-model="dateMenu" :close-on-content-click="false">
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="date"
                :disabled="status !== 'pending'"
                v-bind="attrs"
                outlined
                hide-details
                readonly
                dense
                placeholder="시작일"
                class="mx-3"
                v-on="on"
              />
            </template>
            <v-date-picker v-model="date" @input="dateMenu = false" />
          </v-menu>
          <v-menu v-model="timeMenu" :close-on-content-click="false">
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="time"
                :disabled="status !== 'pending'"
                v-bind="attrs"
                outlined
                hide-details
                readonly
                dense
                placeholder="시간"
                v-on="date ? on : null"
              />
            </template>
            <v-time-picker v-model="time" ampm-in-title @click:minute="timeMenu = false" />
          </v-menu>
        </v-card-actions>
      </v-card>
    </v-row>
    <v-card outlined>
      <v-row no-gutters>
        <v-col cols="5">
          <v-list>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>
                  레포트 구분 값
                  <span class="ml-4" style="font-size: 13px">
                    앱에서 레포트 구분값이 될 항목을 선택해주세요.
                  </span>
                </v-list-item-title>
                <v-row>
                  <v-col>
                    <v-combobox
                      v-model="columnCategory"
                      :items="reportCategoryList"
                      item-text="name"
                      item-value="id"
                      outlined
                      dense
                      hide-details
                      placeholder="레포트 구분값 선택"
                      @change="updateColumnCategory"
                    />
                  </v-col>
                </v-row>
              </v-list-item-content>
            </v-list-item>

            <v-list-item>
              <v-row>
                <v-col cols="6">
                  <v-list-item-title class="mt-2">
                    가격
                    <span class="ml-4" style="font-size: 13px">
                      숫자로만 입력 가능합니다.
                    </span>
                  </v-list-item-title>
                  <v-text-field v-model="input.fish" :rules="[(v) => v > 0]" dense hide-details type="number" outlined />
                </v-col>
                <v-col cols="6">
                  <v-list-item-title class="mt-1 d-flex">
                    <v-checkbox v-model="discountFishInput" :ripple="false" hide-details class="mt-0 pt-0" />
                    할인가 입력
                    <span class="ml-2 mt-1" style="font-size: 13px">
                      가격보다 낮은 숫자로만 입력 가능합니다.
                    </span>
                  </v-list-item-title>
                  <v-text-field
                    v-model="input.discountFish"
                    :disabled="!discountFishInput"
                    :rules="[(v) => v > -1]"
                    dense
                    hide-details
                    type="number"
                    outlined
                  />
                </v-col>
              </v-row>
            </v-list-item>

            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>작성멘토</v-list-item-title>
                <MentorInput v-model="mentor" />
              </v-list-item-content>
            </v-list-item>

            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>제목</v-list-item-title>
                <v-text-field v-model="input.title" outlined dense hide-details />
              </v-list-item-content>
            </v-list-item>

            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>
                  라벨
                  <span class="ml-4" style="font-size: 13px">
                    라벨 1단계 값은 구분값으로, 2~3단계는 태그값으로 프론트에 노출됩니다.
                  </span>
                </v-list-item-title>
                <v-row>
                  <v-col v-for="(e, i) in select" :key="i" cols="4">
                    <v-select
                      v-model="req.select[e.model]"
                      dense
                      hide-details
                      outlined
                      :prepend-inner-icon="`mdi-numeric-${i + 1}-box`"
                      :disabled="e.disabled ? !req.select[e.disabled] : null"
                      :items="res.select[e.model]"
                      :append-icon="null"
                      color="#ffd600"
                      clearable
                    />
                  </v-col>
                </v-row>
              </v-list-item-content>
            </v-list-item>

            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>
                  투명라벨
                  <span class="ml-4" style="font-size: 13px">
                    투명 라벨은 복수 선택 가능합니다.
                  </span>
                </v-list-item-title>
                <InvisibleStampInput v-model="input.invisibleStampList" />
              </v-list-item-content>
            </v-list-item>

            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>
                  썸네일 이미지
                  <span class="ml-4" style="font-size: 13px">
                    썸네일 이미지를 추가해주세요.
                  </span>
                </v-list-item-title>
                <v-card
                  v-if="req.thumbnail"
                  class="mr-3"
                  max-width="277px"
                  height="172px"
                  color="#ffd600"
                  rounded
                  flat
                  :style="`background: url(${req.thumbnail}) no-repeat; background-size: cover`"
                />
                <v-file-input v-model="input.header" outlined dense hide-details prepend-inner-icon="mdi-paperclip" :prepend-icon="null" />
              </v-list-item-content>
            </v-list-item>

            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>멘토의 한마디</v-list-item-title>
                <v-textarea v-model="input.commentOfMentor" rows="1" no-resize outlined dense hide-details @change="(e) => writeText(e, 'commentOfMentor')" />
              </v-list-item-content>
            </v-list-item>

            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>레포트 인트로 제목</v-list-item-title>
                <v-textarea v-model="input.subtitle" rows="1" no-resize outlined dense hide-details @change="(e) => writeText(e, 'subtitle')" />
              </v-list-item-content>
            </v-list-item>

            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>레포트 인트로 문구</v-list-item-title>
                <v-textarea v-model="input.summary" outlined hide-details no-resize />
              </v-list-item-content>
            </v-list-item>

            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>
                  추천학력
                  <span class="ml-4" style="font-size: 13px">
                    추천학력을 등록하시면, 해당 학력의 유저에게 콘텐츠가 추천될 수 있습니다
                  </span>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item>
              전체
              <v-switch v-model="all" inset color="deep-purple accent-1" class="ml-4 mt-0" dense hide-details @change="changeAll" />
            </v-list-item>

            <v-list-item>
              <v-list-item-content>
                <v-row>
                  <v-col cols="12">
                    <v-card outlined class="pa-4">
                      <div v-for="(schoolKind, index) in input.schoolKinds" :key="index">
                        {{ index + 1 }}.
                        <span v-for="(item, i) in schoolKind" :key="i">
                          <span v-if="item">{{ i !== 'education' ? ' -' : '' }} {{ schoolKinds[typeof item === 'object' ? item[0] : item] }}</span>
                        </span>
                        <v-btn light class="ml-3 mb-1" rounded small color="#ffd600" @click="input.schoolKinds.splice(index, 1)" v-text="'삭제'" />
                      </div>
                    </v-card>
                  </v-col>
                  <v-col>
                    <SchoolInput v-model="schoolKind" />
                  </v-col>
                  <v-col cols="2">
                    <v-btn color="#ffd600" light @click="pushSchoolList">
                      추가
                    </v-btn>
                  </v-col>
                </v-row>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-col>

        <v-col cols="7">
          <MarkdownEditor v-model="input.content" class="markdown my-5 mr-5" style="min-height: 700px" />
        </v-col>
      </v-row>
    </v-card>

    <v-card-actions>
      <v-spacer />

      <v-btn color="#7f00ff" large dark @click="createReport">{{ $route.params.uid ? '수정' : '추가' }}하기</v-btn>
    </v-card-actions>

    <v-dialog v-model="notification" trasition="dialog-bottom-transition">
      <v-card>
        <v-card-title>레포트 발송하기</v-card-title>
        <Notification v-if="$route.params.uid" type="column" :idOfType="$route.params.uid" />
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { defineComponent, onBeforeMount, reactive, watch, toRefs } from '@vue/composition-api'
import MarkdownEditor from '@/components/MarkdownEditor.vue'
import Notification from '@/components/Notification.vue'
import InvisibleStampInput from '@/components/input/InvisibleStamp.vue'
import MentorInput from '@/components/input/Mentor.vue'
import SchoolInput from '@/components/input/School.vue'

export default defineComponent({
  components: {
    MarkdownEditor,
    Notification,
    InvisibleStampInput,
    MentorInput,
    SchoolInput,
  },

  setup(props, { root }) {
    const state = reactive({
      all: true,
      notification: false,
      date: null,
      time: null,
      dateMenu: false,
      timeMenu: false,
      thumbnail: null,
      select: [{ model: 'firstDepth' }, { model: 'secondDepth', disabled: 'firstDepth' }, { model: 'thirdDepth', disabled: 'secondDepth' }],
      res: {
        select: {
          firstDepth: [],
          secondDepth: [],
          thirdDepth: [],
        },
      },
      schoolKind: {
        education: null,
        kindList: [],
        yearList: [],
      },
      listMentor: [],
      reportCategoryList: [],
      status: 'keep',
      mentor: null,
      columnCategory: null,
      discountFishInput: false,
      input: {
        fish: 1,
        title: '',
        content: '',
        subtitle: '',
        summary: '',
        commentOfMentor: '',
        stampList: [],
        invisibleStampList: [],
        publishedAt: null,
        mentorId: null,
        columnCategoryId: null,
        discountFish: null,
        header: null,
        schoolKinds: [],
      },
      req: {
        select: {
          firstDepth: null,
          secondDepth: null,
          thirdDepth: null,
        },
      },
      schoolKinds: {
        null: '전체',
        elementary: '초등학교',
        middle: '중학교',
        high: '고등학교',
        nAgain: 'N수',
        parents: '학부모',
        homeSchool: '검정고시',
        mentor: '멘토',
        other: '기타',
        general: '일반',
        autonomous: '자율',
        specialPurpose: '특목',
        specialized: '특성화',
        technical: '전문',
        science: '과학',
        international: '국제',
        art: '예술',
        foreign: '외국어',
        physical: '체육',
        alternative: '대안',
        etc: '기타',
        empty: 'NUL',
        nul: 'NUL',
        1: '1학년',
        2: '2학년',
        3: '3학년',
        4: '4학년',
        5: '5학년',
        6: '6학년',
      },
    })

    const getListReportCategory = async () => {
      state.reportCategoryList = await root.$store.dispatch('report/getListReportCategory')
    }

    const selectNullDepth = async () => {
      const newStamp = await root.$store.dispatch('stamp/getNewStamp', {})

      newStamp.map((e) => {
        state.res.select.firstDepth.push(e.firstDepth)
      })
    }

    const selectFirstDepth = async () => {
      const newStamp = await root.$store.dispatch('stamp/getNewStamp', {
        firstDepth: state.req.select.firstDepth,
      })

      state.res.select.secondDepth = []
      newStamp.map((e) => {
        if (e.secondDepth !== null) state.res.select.secondDepth.push(e.secondDepth)
        else state.input.stampList[0] = Number(e.id)
      })
    }

    const selectSecondDepth = async () => {
      const newStamp = await root.$store.dispatch('stamp/getNewStamp', {
        firstDepth: state.req.select.firstDepth,
        secondDepth: state.req.select.secondDepth,
      })

      state.res.select.thirdDepth = []
      newStamp.map((e) => {
        if (e.thirdDepth !== null) state.res.select.thirdDepth.push(e.thirdDepth)
        else state.input.stampList[0] = Number(e.id)
      })
    }

    const selectThirdDepth = async () => {
      const getNewStamp = await root.$store.dispatch('stamp/getNewStamp', {
        firstDepth: state.req.select.firstDepth,
        secondDepth: state.req.select.secondDepth,
      })

      getNewStamp.map((e) => {
        if (e.thirdDepth === state.req.select.thirdDepth) state.input.stampList[0] = Number(e.id)
      })
    }

    const getOneReport = async (id) => {
      try {
        const report = await root.$store.dispatch('report/getOneReport', {
          id: Number(id),
        })

        state.mentor = report.author
        state.columnCategory = report.ColumnCategory

        state.input.mentorId = Number(report.author.id)
        state.input.title = report.title
        state.input.subtitle = report.subtitle
        state.input.content = report.content
        state.input.discountFish = report.discountFish
        state.input.fish = Number(report.fish)
        state.input.summary = report.summary
        state.input.thumbnail = report.header
        state.input.commentOfMentor = report.commentOfMentor
        state.input.schoolKinds = report.schoolList ?? []

        if (state.input.discountFish !== null) state.discountFishInput = true

        state.input.stampList = [Number(report.stamp[0]?.id)]
        state.req.select.firstDepth = report.stamp[0]?.firstDepth
        await selectFirstDepth()
        state.req.select.secondDepth = report.stamp[0]?.secondDepth
        await selectSecondDepth()
        state.req.select.thirdDepth = report.stamp[0]?.thirdDepth
        await selectThirdDepth()
        state.input.invisibleStampList = report.invisibleStamp

        if (report.schoolList[0]) state.all = false

        if (report.publishedAt) {
          const date = new Date(report.publishedAt)
          const Y = String(date.getFullYear())
          const M = String(date.getMonth() + 1).padStart(2, '0')
          const D = String(date.getDate()).padStart(2, '0')
          const hh = String(date.getHours()).padStart(2, '0')
          const mm = String(date.getMinutes()).padStart(2, '0')
          state.date = Y + '-' + M + '-' + D
          state.time = hh + ':' + mm

          if (new Date(report.publishedAt) > new Date()) {
            state.status = 'pending'
          } else {
            state.status = 'show'
          }
        } else {
          state.status = 'keep'
        }
      } catch (err) {
        console.log(err)
        alert(err)
      }
    }

    const updateReport = async () => {
      try {
        if (!confirm('레포트를 수정합니다.')) return

        let date = null
        if (state.date && state.time) date = new Date(state.date + 'T' + state.time)
        else if (state.date) date = new Date(state.date + 'T00:00')

        if (state.status === 'keep') {
          state.input.publishedAt = null
          state.date = null
          state.time = null
        } else state.input.publishedAt = date ?? new Date()

        await root.$store.dispatch('report/updateReport', {
          columnId: Number(root.$route.params.uid),
          ...state.input,
          fish: state.input.fish ? Number(state.input.fish) : undefined,
          discountFish: state.discountFishInput ? Number(state.input.discountFish) : null,
          thumbnail: state.input.header ?? undefined,
          select: undefined,
          header: undefined,
          invisibleStampList: state.input.invisibleStampList.map((x) => ({ name: x.name, visible: true })),
        })

        alert('레포트가 정상적으로 수정되었습니다.')
      } catch (err) {
        console.log(err)
        alert(err)
      }
    }

    const createReport = async () => {
      if (root.$route.params.uid) return updateReport()

      try {
        let date = null
        if (state.date && state.time) date = new Date(state.date + 'T' + state.time)
        else if (state.date) date = new Date(state.date + 'T00:00')

        if (state.status === 'keep') state.input.publishedAt = null
        else state.input.publishedAt = state.status === 'show' ? new Date() : date

        await root.$store.dispatch('report/createReport', {
          ...state.input,
          fish: Number(state.input.fish),
          discountFish: state.discountFishInput ? Number(state.input.discountFish) : undefined,
          fcmSend: false,
          select: undefined,
          invisibleStampList: state.input.invisibleStampList.map((x) => ({ name: x.name, visible: true })),
        })

        alert('레포트가 정상적으로 추가되었습니다.')
        root.$router.push('/report')
      } catch (err) {
        console.log(err)
        alert(err)
      }
    }

    const updateColumnCategory = (e) => {
      state.input.columnCategoryId = Number(e.id)
    }

    const writeText = (text, field) => {
      let splitText = text.split('\n')
      if (splitText.length <= 1) return

      const fields = ['commentOfMentor', 'subtitle', 'summary']
      let index = fields.indexOf(field)

      while (splitText) {
        if (index + 1 === fields.length) {
          state.input[fields[index]] = splitText.join('\n')
          break
        } else state.input[fields[index++]] = splitText.shift()
      }
    }

    const changeAll = async () => {
      state.input.schoolKinds = []
    }

    const pushSchoolList = async () => {
      state.all = false
      state.input.schoolKinds.push(Object.assign({}, state.schoolKind))
      state.schoolKind = {
        education: null,
        kindList: null,
        yearList: null,
      }
    }

    onBeforeMount(() => {
      selectNullDepth()
      getListReportCategory()
      if (root.$route.params.uid) getOneReport(root.$route.params.uid)
    })

    watch(
      () => state.mentor,
      () => {
        state.input.mentorId = Number(state.mentor.id)
      },
    )

    watch(
      () => state.req.select.firstDepth,
      () => {
        state.req.select.secondDepth = null
        state.req.select.thirdDepth = null
        if (state.req.select.firstDepth) selectFirstDepth()
      },
    )

    watch(
      () => state.req.select.secondDepth,
      () => {
        state.req.select.thirdDepth = null
        if (state.req.select.secondDepth) selectSecondDepth()
        else if (state.req.select.firstDepth) selectFirstDepth()
      },
    )

    watch(
      () => state.req.select.thirdDepth,
      () => {
        if (state.req.select.thirdDepth) selectThirdDepth()
        else if (state.req.select.secondDepth) selectSecondDepth()
        else if (state.req.select.firstDepth) selectFirstDepth()
      },
    )

    return {
      ...toRefs(state),
      updateColumnCategory,
      createReport,
      writeText,
      changeAll,
      pushSchoolList,
    }
  },
})
</script>
