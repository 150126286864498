<template>
  <v-row>
    <v-col v-for="(e, i) in select" :key="i" :cols="cols">
      <v-select
        v-model="schoolKind[e.model]"
        outlined
        dense
        hide-details
        :disabled="e.disabled ? !schoolKind[e.disabled] : false"
        :items="items[e.model]"
        :append-icon="null"
        color="#ffd600"
        clearable
      />
    </v-col>
  </v-row>
</template>

<script>
import { defineComponent, reactive, toRefs, watch } from '@vue/composition-api'

export default defineComponent({
  props: {
    value: {
      type: Object,
    },
    cols: {
      type: Number,
      default: 4,
    },
  },

  setup(props, { emit }) {
    const state = reactive({
      select: [
        { model: 'education', disabled: null },
        { model: 'kindList', disabled: 'education' },
        { model: 'yearList', disabled: 'education' },
      ],
      items: {
        education: [
          { text: '초등학교', value: 'elementary' },
          { text: '중학교', value: 'middle' },
          { text: '고등학교', value: 'high' },
          { text: 'N수', value: 'nAgain' },
          { text: '학부모', value: 'parents' },
          { text: '검정고시', value: 'homeSchool' },
          { text: '멘토', value: 'mentor' },
          { text: '기타', value: 'other' },
        ],
        kindList: [],
        yearList: [],
      },
      kindList: [
        { text: '전체', value: null, education: ['elementary', 'middle', 'high'] },
        { text: '일반', value: ['general'], education: ['middle', 'high'] },
        { text: '자율', value: ['autonomous'], education: ['high'] },
        { text: '특목', value: ['specialPurpose'], education: ['high'] },
        { text: '특성화', value: ['specialized'], education: ['high'] },
        { text: '전문', value: ['technical'], education: ['high'] },
        { text: '과학', value: ['science'], education: ['high'] },
        { text: '국제', value: ['international'], education: ['middle', 'high'] },
        { text: '예술', value: ['art'], education: ['middle', 'high'] },
        { text: '외국어', value: ['foreign'], education: ['high'] },
        { text: '체육', value: ['physical'], education: ['middle', 'high'] },
        { text: '대안', value: ['alternative'], education: ['middle', 'high'] },
        { text: '기타', value: ['etc'], education: ['middle', 'high'] },
        { text: 'NUL', value: ['empty', 'nul'], education: ['middle', 'high'] },
      ],
      yearList: [
        { text: '전체', value: null, education: ['elementary', 'middle', 'high'] },
        { text: '1학년', value: [1], education: ['elementary', 'middle', 'high'] },
        { text: '2학년', value: [2], education: ['elementary', 'middle', 'high'] },
        { text: '3학년', value: [3], education: ['elementary', 'middle', 'high'] },
        { text: '4학년', value: [4], education: ['elementary'] },
        { text: '5학년', value: [5], education: ['elementary'] },
        { text: '6학년', value: [6], education: ['elementary'] },
      ],
      schoolKind: {
        education: null,
        kindList: null,
        yearList: null,
      },
    })

    watch(
      () => state.schoolKind.education,
      () => {
        state.schoolKind.kindList = null
        state.schoolKind.yearList = null
        state.items.kindList = []
        state.items.yearList = []

        if (!state.schoolKind.education) return

        state.items.kindList = state.kindList.filter((e) => {
          if (!e.education) return true

          return e.education.indexOf(state.schoolKind.education) > -1
        })

        state.items.yearList = state.yearList.filter((e) => {
          if (!e.education) return true

          return e.education.indexOf(state.schoolKind.education) > -1
        })
      },
    )

    watch(
      state.schoolKind,
      () => {
        emit('input', state.schoolKind)
      },
      { deep: true },
    )

    watch(
      () => props.value,
      () => {
        state.schoolKind = props.value
      },
    )

    return {
      ...toRefs(state),
    }
  },
})
</script>
