<template>
  <Editor
    ref="editor"
    :initial-value="value"
    :options="editorOptions"
    :height="height"
    initial-edit-type="markdown"
    preview-style="vertical"
    style="background: white"
    @change="onEditorChange"
  />
</template>

<script>
import { defineComponent, reactive, toRefs, watch } from '@vue/composition-api'
import { Editor } from '@toast-ui/vue-editor'
import colorSyntax from '@toast-ui/editor-plugin-color-syntax'
import AWS from 'aws-sdk'
import 'tui-color-picker/dist/tui-color-picker.css'
import '@toast-ui/editor-plugin-color-syntax/dist/toastui-editor-plugin-color-syntax.css'
import '@toast-ui/editor/dist/toastui-editor.css'
import '@toast-ui/editor/dist/theme/toastui-editor-dark.css'

export default defineComponent({
  props: {
    value: String,
    height: {
      type: String,
      default: '500px',
    },
  },

  components: {
    Editor,
  },

  setup(props, { emit }) {
    const state = reactive({
      editor: null,
      editorOptions: {
        minHeight: '300px',
        language: 'en-US',
        useCommandShortcut: true,
        usageStatistics: true,
        hideModeSwitch: false,
        plugins: [colorSyntax],
        hooks: {
          addImageBlobHook: (fileOrBlob, callback) => {
            upload(fileOrBlob)
              .then((value) => {
                callback(value.Location)
              })
              .catch((err) => {
                alert(err)
              })
          },
        },
        toolbarItems: [
          ['heading', 'bold', 'italic', 'strike'],
          ['hr', 'quote'],
          ['ul', 'ol', 'task'],
          ['image', 'link'],
          ['code', 'codeblock'],
        ],
      },
    })

    watch(
      () => props.value,
      () => {
        if (state.editor.invoke('getMarkdown') !== props.value) state.editor.invoke('setMarkdown', props.value)
      },
    )

    const onEditorChange = () => {
      emit('input', state.editor.invoke('getMarkdown'))
    }

    const upload = (blob) => {
      AWS.config.update({
        region: 'ap-northeast-2',
        credentials: new AWS.CognitoIdentityCredentials({
          IdentityPoolId: `${process.env.VUE_APP_IDENTITY_POOL_ID}`,
        }),
      })

      const s3 = new AWS.S3({
        apiVersion: '2006-03-01',
        params: {
          Bucket: 'hakhak-images',
        },
      })

      const upload = s3.upload({
        Key:
          'editor/' +
          'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
            let r = (Math.random() * 16) | 0,
              v = c == 'x' ? r : (r & 0x3) | 0x8
            return v.toString(16)
          }) +
          blob.name.substr(blob.name.lastIndexOf('.')),
        Body: blob,
        ACL: 'public-read',
      })

      const promise = upload.promise()
      return promise
        .then((value) => {
          return value
        })
        .catch((err) => {
          return alert(err)
        })
    }

    return {
      ...toRefs(state),
      onEditorChange,
      upload,
    }
  },
})
</script>
